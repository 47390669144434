import React from 'react';
import { Box, Text, Button, Flex, Image } from 'theme-ui';
import card1 from '../../../public/card1.jpg';
import card2 from '../../../public/personalize.jpg';
import card3 from '../../../public/card3.jpg';
import { useProspectContext } from '../../../context/ProspectContext';
import { clickEvent } from '../../../utils/gtm';

export default function SectionProtectYourHouse() {
  const { setOpen } = useProspectContext();

  return (
    <Box as="section" bg="primary" sx={{ position: 'relative' }}>
      <Text
        pt="6"
        as="h1"
        color="#FFF"
        sx={{
          textAlign: 'center',
          '@media (max-width: 1200px)': {
            fontSize: '50px',
            padding: '3rem 0.5rem 0',
          },
          '@media (max-width: 1024px)': {
            fontSize: '43px',
            padding: '2.5rem 0.5rem 0',
          },
          '@media (max-width: 768px)': {
            fontSize: '32px',
            padding: '1.5rem 0.5rem 0',
          },
          '@media (max-width: 630px)': {
            fontSize: '25px',
            padding: '20px 0.5rem 0',
          },
        }}>
        Proteger sua casa é muito
      </Text>
      <Text
        pb="5"
        as="h1"
        color="#FFF"
        sx={{
          textAlign: 'center',
          '@media (max-width: 1200px)': {
            fontSize: '50px',
          },
          '@media (max-width: 1024px)': {
            fontSize: '43px',
            padding: '0 0.5rem 2rem',
          },
          '@media (max-width: 768px)': {
            fontSize: '32px',
          },
          '@media (max-width: 630px)': {
            fontSize: '25px',
          },
        }}>
        mais fácil com a Flix.
      </Text>
      <Box
        sx={{
          background: `url(./fullBackground.svg)`,
          backgroundColor: 'primary',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          position: 'inherit',
          display: 'flex',
          height: 'auto',
          '@media screen and (max-width: 960px)': {
            background: 'none',
          },
        }}>
        <Box
          sx={{
            margin: '0 auto',
            position: 'absolute',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            '@media screen and (max-width: 960px)': {
              position: 'inherit',
              minWidth: '100%',
            },
          }}>
          <Flex
            sx={{
              position: 'relative',
              width: '100%',
              justifyContent: 'center',
              '@media screen and (max-width: 960px)': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              },
            }}>
            {renderCard(
              'Descubra',
              `Você preenche algumas informações do seu imóvel e a cobertura mensal desejada
              e nós indicamos o plano Flix Residencial mais adequado pra você!`,
              card1,
              setOpen,
            )}
            {renderCard(
              'Personalize',
              `Personalize
Você pode adicionar ou remover a assistência Eletroassist sempre que quiser, sem multa ou carência.`,
              card2,
              setOpen,
            )}
            {renderCard(
              'Contrate e desfrute!',
              `O valor do plano que mais combina com você será apresentado, e aí é só cadastrar
              seu cartão de crédito e pronto! Conte com a assistência da Flix 24h por dia!`,
              card3,
              setOpen,
            )}
          </Flex>
          <Flex sx={{ position: 'relative' }}>
            <Button
              onClick={() => {
                setOpen(true);
                clickEvent('clique_btn_dobra_4');
              }}
              variant="secondary"
              title="Eu quero Flix"
              sx={{
                minWidth: '245px',
                transition: '.3s ease',
                margin: '3rem auto',
                backgroundColor: 'transparent',
                '&:hover': {
                  color: '#FFF',
                  backgroundColor: 'primary',
                },
                '@media screen and (max-width: 930px)': {
                  backgroundColor: 'primary',
                  color: '#FFF',
                  borderColor: '#FFF',
                  margin: '1.5rem auto',
                  '&:hover': { backgroundColor: '#FFF', color: 'primary' },
                },
                '@media screen and (max-width: 768px)': {
                  margin: '0.5rem auto 1.5rem',
                },
              }}>
              Eu quero Flix
            </Button>
          </Flex>
        </Box>

        <Image
          src="./fullBackground.svg"
          sx={{
            position: 'inherit',
            visibility: 'hidden',
          }}
        />
      </Box>
    </Box>
  );
}

const renderCard = (
  title: string,
  text: string,
  imageUrl: string,
  setOpen?: (arg0: boolean) => void,
) => {
  return (
    <Box
      bg="#FFF"
      mx="1rem"
      onClick={() => {
        if (setOpen) {
          setOpen(true);
        }
        clickEvent('clique_btn_dobra_4' + title);
      }}
      sx={{
        maxWidth: '300px',
        width: '100%',
        // cursor: 'pointer',
        boxShadow:
          '0px 40px 64px rgba(44, 44, 82, 0.1), 0px 24px 32px rgba(44, 44, 82, 0.1), 0px 16px 16px rgba(44, 44, 82, 0.1)',
        '@media screen and (max-width: 960px)': {
          maxWidth: '350px',
          width: '100%',
          marginTop: '0',
          marginBottom: '10px',
        },
        '@media screen and (max-width: 570px)': {
          maxWidth: '300px',
          minWidth: '300px',
          height: 'auto',
        },
      }}>
      <Box
        sx={{
          background: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '200px',
          '@media screen and (max-width: 960px)': {
            maxWidth: '528px',
            width: '100%',
            height: '180px',
          },
          '@media screen and (max-width: 570px)': {
            height: '150px',
            maxWidth: '300px',
          },
        }}
      />
      <Box p="0 2rem 2rem 2rem">
        <Text
          as="p"
          sx={{
            fontWeight: 'bold',
            fontSize: '1.3rem',
            '@media screen and (max-width: 960px)': {
              fontSize: '1.5rem',
              marginTop: '1rem',
            },
            '@media screen and (max-width: 570px)': {
              fontSize: '1.3rem',
              marginTop: '0',
            },
          }}>
          {title}
        </Text>
        <Text
          as="p"
          sx={{
            '@media screen and (max-width: 960px)': {
              marginTop: '0.5rem',
              fontSize: '1.1rem',
            },
            '@media screen and (max-width: 570px)': {
              marginTop: '1rem',
              fontSize: '1rem',
            },
          }}>
          {text}
        </Text>
      </Box>
    </Box>
  );
};

import React from 'react';
import { Box, Text, Flex, Image, Grid } from 'theme-ui';

type Props = {
  title: string;
  description: string;
  imagePath: string;
};

export default function TopicDescription(props: Props) {
  const { title, description, imagePath } = props;

  return (
    <Box
      sx={{
        marginTop: '50px',
        marginBottom: '50px',
        '@media screen and (max-width: 1200px)': {
          maxWidth: '500px',
          margin: '0 auto 60px',
        },
      }}>
      <Grid
        columns={[2, '1fr 3fr']}
        sx={{
          '@media screen and (max-width: 1200px)': {
            justifySelf: 'end',
          },
          '@media screen and (max-width: 630px)': {
            display: 'flex',
            flexDirection: 'column',
            marginRight: '0',
          },
        }}>
        <Image
          sx={{
            justifySelf: 'end',
            marginRight: '1.5rem',
            '@media screen and (max-width: 1200px)': {
              justifySelf: 'end',
              marginRight: '0.5rem',
            },
            '@media screen and (max-width: 630px)': {
              display: 'flex',
              flexDirection: 'column',
              marginRight: '0',
              maxWidth: '145px',
              margin: '0 auto',
            },
          }}
          src={imagePath}
        />
        <Flex sx={{ flexDirection: 'column' }}>
          <Text
            as="p"
            mb="2"
            sx={{
              fontWeight: 'bold',
              color: 'primary',
              fontSize: '22px',
              '@media screen and (max-width: 630px)': {
                textAlign: 'center',
                paddingTop: '0',
                marginBottom: '0',
              },
            }}>
            {title}
          </Text>
          <Text
            as="p"
            color="darkGray"
            sx={{
              fontSize: '16px',
              '@media screen and (max-width: 630px)': {
                textAlign: 'center',
              },
            }}>
            {description}
          </Text>
        </Flex>
      </Grid>
    </Box>
  );
}

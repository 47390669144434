import React from 'react';
import HeroContent from '../components/Home/SectionHeroContent/SectionHeroContent';
import EmergencialDouble from '../components/Home/SectionEmergencial/SectionEmergencial';
import FlixAndDot from '../components/Home/SectionFlixAndDot/SectionFlixAndDot';
import HasSolution from '../components/Home/SectionHasSolution/SectionHasSolution';
import SectionProtectYourHouse from '../components/Home/SectionProtectYourHouse/SectionProtectYourHouse';
import SectionYouMoreSafe from '../components/Home/SectionYouMoreSafe/SectionYouMoreSafe';
import SectionAppStore from '../components/Home/SectionAppStore/SectionAppStore';
import { Box } from 'theme-ui';
import Container from '../components/Shared/Container/Container';
import { ProspectProvider } from '../context/ProspectContext';

export default function Home() {
  return (
    <ProspectProvider>
      <Container>
        <Box pt="5%">
          <HeroContent />
          <EmergencialDouble />
          <HasSolution />
          <SectionProtectYourHouse />
          <SectionYouMoreSafe />
          {/*<SectionAppStore />*/}
          <FlixAndDot />
        </Box>
      </Container>
    </ProspectProvider>
  );
}

import React from 'react';
import { Box, Text, Flex, Image } from 'theme-ui';
import TopicDescription from '../../Home/TopicDescription/TopicDescription';

export default function SectionHasSolution() {
  return (
    <Box
      id="solution"
      as="section"
      sx={{
        padding: '6rem 1rem',
        '@media (max-width: 1400px)': { padding: '5rem 15px' },
        '@media (max-width: 1300px)': { padding: '3.5rem 15px' },
        '@media (max-width: 1200px)': { padding: '3rem 15px' },
        '@media (max-width: 1024px)': { padding: '2.5rem 15px 2rem' },
        '@media (max-width: 768px)': { padding: '2rem 15px 1rem' },
        '@media (max-width: 630px)': { padding: '30px 15px 15px' },
      }}>
      <Flex sx={{ alignItems: 'center', flexDirection: 'column' }}>
        <Image src="./arc.svg" width="50px" />
        <Text
          as="h2"
          sx={{
            '@media (max-width: 1200px)': { fontSize: '48px' },
            '@media (max-width: 1024px)': { fontSize: '40px' },
            '@media (max-width: 768px)': { fontSize: '35px' },
          }}>
          Tem solução
        </Text>
        <Text
          as="p"
          sx={{
            fontSize: '22px',
            color: 'darkGray',
            '@media (max-width: 1200px)': { fontSize: '20px' },
            '@media (max-width: 1024px)': {
              fontSize: '18px',
              textAlign: 'center',
            },
            '@media (max-width: 768px)': { fontSize: '17px' },
          }}>
          Adicione ao seu plano a assistência que cuida dos seus
          eletroeletrônicos
        </Text>
      </Flex>
      <Flex
        sx={{
          maxWidth: '1200px',
          margin: '60px auto 0',
          justifyContent: 'center',
          '@media (max-width: 1200px)': {
            margin: '30px auto 0',
            alignItems: 'center',
          },
          '@media (max-width: 1024px)': {
            margin: '25px auto 0',
          },
          '@media (max-width: 768px)': { marginTop: '15px auto 0' },
        }}>
        <Box
          sx={{
            background: `url(./temsolucao.svg)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left',
            maxWidth: '530px',
            width: '100%',
            '@media screen and (max-width: 1200px)': { display: 'none' },
            minHeight: '400px',
          }}></Box>
        <Flex
          sx={{
            flexDirection: 'column',
            width: '100%',
            alignSelf: 'center',
          }}>
          <TopicDescription
            title="Eletroassist"
            description="A TV te deixou na mão no meio da sua série favorita? A geladeira pifou e o sorvete derreteu?
			 A Flix Eletroassist está aqui para você: nosso seguro cobre o conserto de vários eletrodomésticos!"
            imagePath="./eletroassist.svg"
          />
          {/*   <TopicDescription*/}
          {/*     title="Help Desk"*/}
          {/*     description="Para garantir produtividade e conforto em seu home office, criamos o Flix Help Desk.*/}
          {/*Conte com nosso suporte para reparos em Desktops, Notebooks e Periféricos."*/}
          {/*     imagePath="./helpdesk.svg"*/}
          {/*   />*/}
          {/*   <TopicDescription*/}
          {/*     title="Pet"*/}
          {/*     description="Assim como você, queremos uma vida longa pro seu melhor amigo! O Flix Pet é o nosso plano assistencial*/}
          {/*com consultas veterinárias, informações sobre vacinas e muito mais, tudo isso para garantir a qualidade de vida do seu pet."*/}
          {/*     imagePath="./pet.svg"*/}
          {/*   />*/}
        </Flex>
      </Flex>
    </Box>
  );
}
